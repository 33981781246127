<template>
    <div id="manage">
        <div class="manage-content">
            <div class="manage-content_coll">
                <el-card>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <span class="content_coll_title">导出任务</span>
                            </template>
                            <!--<div class="manage-centerOrAdd clearfix">
                                <el-input
                                        class="manage-searchVal"
                                        clearable
                                        size="mini"
                                        v-model="searchVal"
                                        placeholder="请输入中心编号/名称"
                                ></el-input>
                                <el-button
                                        class="manage-searchBtn"
                                        icon="el-icon-search"
                                        size="mini"
                                        type="primary"
                                        @click="handleFilter"
                                >查询
                                </el-button>
                            </div>-->
                            <!-- 导出任务列表 -->
                            <el-table
                                    v-loading="excelTaskListLoading"
                                    :data="exportTaskList"
                                    style="width: 100%;margin-top:20px"
                                    border
                                    :header-cell-class-name="tableHeaderCn"
                                    :row-class-name="tableRowCn"
                            >
                                <el-table-column  align='center' label="导出编码" width="180" type="index" ></el-table-column>
                                <el-table-column  align='center' prop="name" label="文件名称"></el-table-column>
                                <el-table-column  align='center' prop="createTime" label="导出时间"></el-table-column>
                                <el-table-column  align='center' prop="createBy" label="操作用户"></el-table-column>
                                <el-table-column  align='center' label="状态">
                                    <!--<template slot-scope="scope">{{scope.row.status===0?'未启用':'启用'}}</template>-->
                                    <template slot-scope="scope" >

                                    </template>
                                    <template slot-scope="scope" >
                                        <div v-if="scope.row.status === 0">
                                            <i class="el-icon-upload" style="color: blue;"></i>   正在导出，完成后可下载
                                        </div>
                                        <div v-else-if="scope.row.status === 1">
                                            <i class="el-icon-success" style="color: green;"></i>   导出成功
                                        </div>
                                        <div v-else-if="scope.row.status === 2">
                                            <i class="el-icon-error" style="color: red;"></i>   导出失败
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="100">
                                    <template slot-scope="scope">
                                        <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
                                        <el-button v-if="scope.row.status == 2" type="text" size="medium" @click="refreshExcel(scope.row)" v-authorizeRole="{'itemCode':'crf_export_reload',menuContainer:$authorContainer}">
                                            <i class="el-icon-refresh-left"></i>
                                        </el-button>
                                        <el-button v-if="scope.row.status == 1" type="text" size="medium" @click="downloadExcel(scope.row)" v-authorizeRole="{'itemCode':'crf_export_download',menuContainer:$authorContainer}">
                                            <i class="el-icon-download"></i>
                                        </el-button>
                                        <el-button v-if="scope.row.status != 0" type="text" size="medium" v-authorizeRole="{'itemCode':'crf_export_del',menuContainer:$authorContainer}">
                                            <el-popconfirm title="您确定要删除该任务吗？" @confirm="removeExportExcelTask(scope.row)">
                                                <i slot="reference" class="el-icon-delete"></i>
                                            </el-popconfirm>
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item name="2">
                            <template slot="title">
                                <span class="content_coll_title">导入任务</span>
                            </template>
                            <!-- 用户管理列表 -->
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </div>
        </div>
    </div>

</template>

<script>

    import {getResearchExcel,downExcel,deleteExportTask,refreshExportTask} from "@/api/excelTask";

    export default {
        name: "Manage",
        data() {
            return {
                researchCode: this.$route.query.researchCode,
                researchName: this.$route.query.researchName,
                versionCode: JSON.parse(localStorage.getItem('versionCode')),
                dialogStatus: "",
                loading: false,
                searchVal: "",
                activeNames: [],
                excelTaskListLoading: false,
                // 用户
                searchUserVal: "",
                exportTaskList:[],
            };
        },
        created() {
            this.getExportExcelTaskList();
//          this.getUsersList();
        },
        methods: {
            tableHeaderCn() {
                return "manage-table_header";
            },
            tableRowCn() {
                return "manage-table_row";
            },
            getExportExcelTaskList(val) {
                this.excelTaskListLoading = true;
                if (val === "add") {
                    this.searchVal = "";
                }
                getResearchExcel(this.researchCode).then(res => {
                    console.log("----elcelTask----"+JSON.stringify(res))
                    this.excelTaskListLoading = false;
                    this.exportTaskList = res.data;
                }).catch(error => {
                    this.excelTaskListLoading = false;
                });
            },
            handleFilter() {
                this.getExportExcelTaskList();
            },
            downloadExcel(item){
                let href = this.$host + '/api/tSiteResearchExcel/downExcel?fileName='+item.name;
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = href;
                link.setAttribute('download', item.name);
                document.body.appendChild(link);
                link.click()
//          	    window.open(href);
            },
            refreshExcel(row){
                console.log("---删除----"+JSON.stringify(row))
                refreshExportTask(this.researchCode,row.id)
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: res.data
                        });
                        this.getExportExcelTaskList();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            removeExportExcelTask(row) {
                console.log("---删除----"+JSON.stringify(row))
                deleteExportTask(row.id)
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: res.data
                        });
                        this.getExportExcelTaskList();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getTypeList(row) {
                // 所属
                if (row.type === 0) {
                    return "研究";
                } else if (row.type === 1) {
                    let list = [];
                    row.list.forEach(item => {
                        list.push(item.centerName);
                    });
                    return list.join(",");
                }
            },
        }
    };
</script>

<style scoped lang="scss">

    .el-button--medium {
        font-size: 18px;
    }
    /deep/.el-card__body{
        padding: 10px;
    }
    .el-collapse-item {
        margin-bottom: 10px;
    }
    .el-collapse, .el-collapse-item__header, .el-collapse-item__wrap {
        border-top: none;
    }
    .el-collapse{
        border: none;
    }
    .el-table{
        margin-top: 0 !important;
    }

    #manage {
        height: 100%;
        // background: rgba(73, 150, 255, 0.14);
        .manage-add {
            // text-align: left;
            height: 40px;
            line-height: 40px;
            .manage-title {
                height: 16px;
                font-size: 16px;
                color: #212121;
                margin-left: 16px;
                margin-right: 32px;
            }
            button {
                padding: 0 16px;
                height: 24px;
                line-height: 24px;
                font-size: 12px;
                color: #044e9a;
                background: #b3dfff;
                border-radius: 2px;
                margin: 8px 16px;
                border: none;
            }
        }
        .manage-content {
            position: absolute;
            top: 120px;
            bottom: 16px;
            left: 16px;
            right: 16px;
            background: #fff;
            // padding-top: 24px;
            overflow-y: auto;
            .manage-searchVal {
                width: 240px;
                margin: 0 20px 0 10px;
            }
            .manage-searchBtn {
                color: #044e9a;
                background: #b3dfff;
                border-radius: 2px;
                border: none;
            }
            .manage-content_coll {
                position: relative;
                .content_coll_title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #212121;
                    line-height: 24px;
                    margin-left: 42px;
                }
                .content_coll_add {
                    position: absolute;
                    right: 10px;
                    width: 120px;
                    height: 32px;
                    font-size: 16px;
                    color: #fff;
                }
            }
        }
    }
</style>
<style lang="scss">
    .manage-content_coll .el-collapse-item__arrow {
        position: absolute;
        font-size: 24px;
        color: #8a8c91;
    }
    .el-icon-arrow-right{
        margin: 0 !important;
    }
    .manage-content .el-collapse,
    .manage-content .el-collapse-item__header,
    .manage-content .el-collapse-item__wrap {
        border: none;
    }

    .manage-table_header {
        height: 48px;
        background-color: #f5f5f5 !important;
        border-right: none !important;
    }

    .manage-table_row td {
        height: 42px;
        padding: 0;
    }
    #manage{
        .el-collapse-item__header:hover {
            background: #F5F7FA;
        }

        .el-collapse-item__wrap {
        }
    }
    .el-collapse-item__header {
        height: 60px !important;
        line-height: 60px !important;
        &:hover {
            background: #F5F7FA;
        }
    }
    .el-collapse-item__content{
        padding-bottom: 0 !important;
    }
</style>
