import Vue from 'vue'
import request from './axios'

//获取研究下的所有导出任务
export function getResearchExcel(researchCode) { 
  return request({
    url: '/api/tSiteResearchExcel/getBeanByResearchCode',
    method: 'GET',
    params: {
      researchCode
    },
  })
}

// 添加导出任务
export function addExportTask(researchCode,versionCode) {
  return request({
    url: '/api/tSiteResearchExcel/addExportTask',
    method: 'POST',
    data: {
      researchCode,
      versionCode
    }
  })
}

export function refreshExportTask(researchCode,id){
	return request({
    url: '/api/tSiteResearchExcel/refreshExportTask',
    method: 'get',
    params: {
    	researchCode,
      id
    }
  })
}

//下载Excel
export function downExcel(fileName) { 
  return request({
    url: '/api/tSiteResearchExcel/downExcel',
    method: 'GET',
    params: {
      fileName
    },
  })
}

//删除导出任务
export function deleteExportTask(id) { 
  return request({
    url: '/api/tSiteResearchExcel/deleteExportById',
    method: 'GET',
    params: {
      id
    },
  })
}