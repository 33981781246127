<template>
  <div id="TesteeTrace">
    {{msg}}
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'TesteeTrace',
  components: {
    // HelloWorld
  },
  data(){
	return {
    msg: '稽查痕迹',
	}  
  },
  created(){

  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
	#TesteeTrace{
		position: absolute;
    top: 102px;
    bottom: 16px;
    left: 16px;
    right: 16px;
		background: #fff;
    padding: 1%;
    min-width: 720px;
	}
</style>